import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import { initLiff, setupLiff } from "../lib/liff"

export default function Home() {
  const [message, setMessage] = useState({})
  useEffect(() => {
    setupLiff()
      .then(res => {
        setMessage(res)
      })
      .catch(err => {
        setMessage(err)
      })
  }, [])
  return <div></div>
}
